import { Brand, Cheese, Format } from '../../graphql/generated/api-graphql'
import { HighlightBlockProps } from '../../components/molecules/HighlightBlock'
import { wediaImagesTransform } from '../Common/wedia'

const highlightTransformer = (
  t: any,
  data?: Cheese | Brand,
  popinHandler?: (data: Format) => void
): HighlightBlockProps | undefined => {
  if (!data) return undefined

  const isBrandType = 'highlightArticles' in data

  const hasContent =
    (data.highlightBody || data.highlightTitle) && data?.highlightFormat

  if (!hasContent) return undefined

  return {
    category: data?.highlightSubtitle ?? '',
    title: data?.highlightTitle ?? '',
    htmlProps: {
      text: data?.highlightBody ?? '',
    },
    actionButtonProps: data?.highlightFormat
      ? {
          label: t('discover_the_cheese'),
          onClick: () =>
            data.highlightFormat && popinHandler?.(data.highlightFormat),
        }
      : undefined,
    imageProps:
      data?.highlightFormat && data?.highlightFormat?.packshot
        ? {
            withHD: true,
            maxWidth: isBrandType ? 580 : 420,
            images: wediaImagesTransform(data?.highlightFormat?.packshot),
            objectFitContain: true,
          }
        : undefined,
  }
}

export default highlightTransformer
