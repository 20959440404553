import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import ActionButton from '../../atoms/ActionButton'
import EcoPush from '../../atoms/EcoPush'
import RelatedRange from '../RelatedRange'
import { media } from '../../../theme/mixins'
import Html from '../../atoms/Html'

export const RelatedProductsBlock = styled.section`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  padding: 5rem 1.5rem;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.arapawa};
  background: ${({ theme }) => theme.colors.snow};
  @media ${mq(sizes.tablet)} {
    padding: 6rem 4rem;
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2}
  margin: 0;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`

export const RangeTitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH3}
  margin: 2rem 0 0 0;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH2}
  }
`

export const RangeBody = styled(Html)`
  margin-top: 2rem;
  @media ${mq(sizes.tablet)} {
    margin-top: 3rem;
  }
`

export const Ranges = styled.div`
  &:not(:first-child) {
    margin-top: 4rem;
  }
`
export const Range = styled(RelatedRange)<{
  $isOpen: boolean
  $defaultNumberOnMobile: number
  $defaultNumberOnDesktop: number
}>`
  transition: opacity 200ms ease-in-out;

  &:not(:first-child) {
    margin-top: 4rem;
  }

  ${({ $isOpen, $defaultNumberOnMobile, $defaultNumberOnDesktop }) =>
    !$isOpen &&
    css`
      &:nth-child(n + ${$defaultNumberOnMobile + 1}) {
        position: absolute;
        height: 0;
        opacity: 0;
        visibility: hidden;
        ${media(sizes.tablet)} {
          position: inherit;
          height: inherit;
          opacity: inherit;
          visibility: inherit;
        }
      }

      &:nth-child(n + ${$defaultNumberOnDesktop + 1}) {
        ${media(sizes.tablet)} {
          position: absolute;
          height: 0;
          opacity: 0;
          visibility: hidden;
        }
      }
    `}
`
export const ActionMore = styled(ActionButton)<{
  $length: number
  $defaultNumberOnMobile: number
  $defaultNumberOnDesktop: number
}>`
  align-self: center;
  &:not(:first-child) {
    margin-top: 4rem;
  }
  @media ${mq(sizes.tablet)} {
    align-self: flex-start;
  }

  ${({ $length, $defaultNumberOnMobile }) =>
    $length < $defaultNumberOnMobile &&
    css`
      display: none;
    `}

  @media ${mq(sizes.tablet)} {
    display: flex;
    ${({ $length, $defaultNumberOnDesktop }) =>
      $length < $defaultNumberOnDesktop &&
      css`
        display: none;
      `}
  }
`
export const Push = styled(EcoPush)`
  &:not(:first-child) {
    margin-top: 4rem;
  }
  @media ${mq(sizes.tablet)} {
  }
`
