import React, { FC } from 'react'
import cx from 'classnames'

import { ActionButtonProps } from '../../atoms/ActionButton'
import { PictureProps } from '../../atoms/Picture'
import { HtmlProps } from '../../atoms/Html'

import * as SC from './styled'
import { HighlightImageRatio, HighlightLayout } from './types'

export type HighlightBlockProps = MinimalSeoBlock & {
  className?: string
  category?: string
  title?: string
  htmlProps?: HtmlProps
  actionButtonProps?: ActionButtonProps
  imageProps?: PictureProps & { objectFitContain?: boolean }
  layout?: HighlightLayout
  imageRatio?: HighlightImageRatio
}

const HighlightBlock: FC<HighlightBlockProps> = (props) => {
  const {
    className,
    htmlTag,
    category,
    title,
    htmlTitleTag,
    htmlProps,
    actionButtonProps,
    imageProps,
    layout = HighlightLayout.Default,
    imageRatio = HighlightImageRatio.Default,
  } = props

  return (
    <SC.HighlightBlock
      className={cx('HighlightBlock', className)}
      as={htmlTag}
      $layout={layout}
    >
      <SC.Content>
        {category && <SC.Theme>{category}</SC.Theme>}
        {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
        {htmlProps && <SC.Text {...htmlProps} />}
        {actionButtonProps && <SC.Action {...actionButtonProps} />}
      </SC.Content>
      {imageProps && (
        <SC.StyledPicture {...imageProps} $imageRatio={imageRatio} />
      )}
    </SC.HighlightBlock>
  )
}

export default HighlightBlock
