import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo } from 'react'

import { actions, selectors } from '../../redux'
import { useServiceRequest } from '../../hooks/useServiceRequest'
import { CouponCardsBlockProps } from '../../components/molecules/CouponCardsBlock'
import { brandCouponsTransformer } from '../Brand/brandCouponsTransformer'
import { PagesByTemplate } from '../Page/PageByTemplate'
import { HighcoCoupon, PageTemplate } from '../../graphql/generated/api-graphql'
import Router from '../../routes/Router'

export const useBrandCoupons = (
  id?: string,
  limit?: number,
  pagesByTemplate?: PagesByTemplate,
  model?: string
): CouponCardsBlockProps | undefined => {
  const { t } = useTranslation()
  const isConnected = useSelector(selectors.auth.isConnected)
  const isInit = useSelector(selectors.app.isInit)
  const cart = useSelector(selectors.coupons.cart)
  const selectedCoupons = useMemo(
    () => cart?.listCoupon?.map((c) => c?.couponCode ?? ''),
    [cart]
  )
  const [coupons, loadCoupons] = useServiceRequest(
    selectors.coupons.brandCoupons,
    actions.coupons.brandCouponsRequest,
    actions.coupons.brandCouponsReset
  )

  const onComplete = useCallback(() => {
    Router.push(pagesByTemplate?.[PageTemplate.Coupons]?.slug ?? '')
  }, [pagesByTemplate])

  const [, handleAddToCart] = useServiceRequest(
    selectors.coupons.addToCart,
    actions.coupons.addToCartRequest,
    actions.coupons.addToCartReset,
    onComplete
  )
  const [, handleRemoveFromCart] = useServiceRequest(
    selectors.coupons.removeFromCart,
    actions.coupons.removeFromCartRequest,
    actions.coupons.removeFromCartReset,
    onComplete
  )

  const onSelect = useCallback(
    (coupon?: HighcoCoupon) => {
      if (isConnected) {
        if (selectedCoupons?.includes(coupon?.couponCode ?? '')) {
          handleRemoveFromCart({
            couponCode: coupon?.couponCode ?? '',
          })
        } else {
          handleAddToCart({
            couponCode: coupon?.couponCode ?? '',
          })
        }
      } else {
        Router.push(pagesByTemplate?.[PageTemplate.Coupons]?.slug ?? '')
      }
    },
    [
      handleAddToCart,
      handleRemoveFromCart,
      isConnected,
      pagesByTemplate,
      selectedCoupons,
    ]
  )

  useEffect(() => {
    if (id) {
      loadCoupons({ id, model })
    }
  }, [isConnected, loadCoupons, id, model])

  return isInit
    ? brandCouponsTransformer(
        t,
        coupons?.data,
        limit,
        selectedCoupons,
        onSelect
      )
    : undefined
}
