import { ArticleType, TagItemType } from '../../graphql/generated/api-graphql'
import routes, { RouteDefinition } from '../../routes/definitions'

export const resolveArticleRoute = (article?: {
  __typename?: string | null
  type?: ArticleType | TagItemType | string | null
  articleType?: ArticleType | string | null
}): RouteDefinition => {
  const isCulinaryArticle =
    article?.__typename === 'CulinaryArticle' ||
    article?.type === TagItemType.CulinaryArticle

  const isHealthArticle = article?.type === 'HEALTH'

  const isCultureArticle =
    article?.__typename === 'Article' ||
    article?.type === ArticleType.Cheese ||
    article?.articleType === ArticleType.Cheese

  if (isCulinaryArticle) {
    return routes.culinaryArticle
  }

  if (isHealthArticle) {
    return routes.healthArticle
  }

  if (isCultureArticle) {
    return routes.cultureArticle
  }

  return routes.healthArticle
}
