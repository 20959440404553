import tracking from 'src/tracking'

import { Brand, Maybe } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { CardsBlockProps } from '../../components/molecules/CardsBlock'
import { transformArticleBigCard } from '../ArticleCard/transformArticleBigCard'
import { transformArticleCard } from '../ArticleCard/transformArticleCard'
import { Icons } from '../../components/atoms/Icon'

export const brandArticlesTransformer = (
  t: any,
  data?: Brand | Maybe<Brand>
): CardsBlockProps | undefined => {
  if (
    !data ||
    !data?.highlightArticles ||
    data?.highlightArticles?.length === 0
  )
    return undefined

  // 1 big if 1, 2 smalls if 1, 1 big and as many smalls otherwise
  const articles = data.highlightArticles
  const highlighted = articles.length !== 2 ? articles[0] : undefined
  const cards = articles.length !== 2 ? articles.slice(1) : articles

  return {
    title: t('brand_news_title'),
    highlighted:
      highlighted &&
      transformArticleBigCard(t, highlighted, t('brand_news_title'), false),
    cards:
      cards.length > 0
        ? cards?.map((article) =>
            transformArticleCard(t, article, t('brand_news_title'), false)
          )
        : undefined,
    actionButtonProps: {
      label: t('more_articles'),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
      href: Router.getRouteUrl(routes.articles),
      onClick: () => tracking.cta(t('more_articles'), t('brand_news_title')),
    },
  }
}
