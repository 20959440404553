import { useTranslation } from 'react-i18next'

import { BreadcrumbProps } from '../../components/molecules/Breadcrumb'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'
import { app } from '../../configuration'

const useBreadcrumb = (
  entries?: BreadcrumbProps['entries']
): BreadcrumbProps => {
  const { t } = useTranslation()

  return {
    entries: entries
      ? [
          {
            label: t('breadcrumb_home'),
            href: Router.getRouteUrl(routes.page, { slug: '/' }),
          },
          ...entries.filter((obj) => Object.keys(obj).length !== 0),
        ]
      : [],
    jsonLdBasePath: app.APP_URL,
  }
}

export default useBreadcrumb
