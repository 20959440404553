import { CouponCardsBlockProps } from '../../components/molecules/CouponCardsBlock'
import { HighcoCoupon } from '../../graphql/generated/api-graphql'
import { couponTransformer } from '../Coupons/couponTransformer'

export const brandCouponsTransformer = (
  t: any,
  coupons?: Array<HighcoCoupon>,
  limit?: number,
  selectedIds?: string[],
  onSelect?: (coupon?: HighcoCoupon) => void
): CouponCardsBlockProps | undefined =>
  coupons && (coupons?.length ?? 0) > 0
    ? {
        title: t('current_promotions'),
        cards: coupons
          .map((coupon) =>
            couponTransformer(
              t,
              coupon,
              selectedIds?.includes(coupon?.couponCode ?? ''),
              () => onSelect?.(coupon)
            )
          )
          .slice(0, limit ?? coupons.length),
      }
    : undefined
