import styled from 'styled-components'

import ActionButton from '../../atoms/ActionButton'
import Picture from '../../atoms/Picture'
import { mq, sizes } from '../../../theme'

export const RelatedProduct = styled.article`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
    display: grid;
    grid-template-columns: 15rem 1fr;
    grid-template-rows: auto auto;
    grid-gap: 2rem;
    justify-items: center;
    padding: 1rem 1rem;
  }
`
export const StyledPicture = styled(Picture)`
  width: 19rem;
  aspect-ratio: 1 / 1;
  padding: 1rem 1rem 0;
  &,
  & img {
    display: block;
  }
  & img {
    width: 100%;
    object-fit: contain;
  }
  @media ${mq(sizes.tablet)} {
    width: 100%;
    padding: 0;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
`
export const Title = styled.h4`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.arapawa};
  margin: 0;
  text-align: center;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  @media ${mq(sizes.tablet)} {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-self: flex-end;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`
export const Actions = styled.div`
  display: flex;
  flex-flow: column nowrap;
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  @media ${mq(sizes.tablet)} {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: flex-start;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`
export const Action = styled(ActionButton)`
  min-width: 14rem;
  &:not(:first-child) {
    margin-top: 1rem;
  }
`
