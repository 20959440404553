import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'

import { RoundedCorners } from './types'

export const Baggy = styled.div``
export const Container = styled.div<{
  $backgroundColor?: string
  $roundedCorners?: RoundedCorners
  $isRoundedOnMobile?: boolean
}>`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? 'transparent'};
  ${({ $roundedCorners, $isRoundedOnMobile }) =>
    $isRoundedOnMobile &&
    ($roundedCorners === RoundedCorners.Both ||
      $roundedCorners === RoundedCorners.Top) &&
    css`
      border-top-left-radius: var(--radius-blocks--mobile);
      border-top-right-radius: var(--radius-blocks--mobile);
      & > :first-child {
        border-top-left-radius: var(--radius-blocks--mobile);
        border-top-right-radius: var(--radius-blocks--mobile);
        margin-top: 0 !important;
      }
    `}
  ${({ $roundedCorners, $isRoundedOnMobile }) =>
    $isRoundedOnMobile &&
    ($roundedCorners === RoundedCorners.Both ||
      $roundedCorners === RoundedCorners.Bottom) &&
    css`
      border-bottom-left-radius: var(--radius-blocks--mobile);
      border-bottom-right-radius: var(--radius-blocks--mobile);
      & > :last-child {
        border-bottom-left-radius: var(--radius-blocks--mobile);
        border-bottom-right-radius: var(--radius-blocks--mobile);
        margin-bottom: 0 !important;
      }
    `}
  @media ${mq(sizes.tablet)} {
    ${({ $roundedCorners }) =>
      ($roundedCorners === RoundedCorners.Both ||
        $roundedCorners === RoundedCorners.Top) &&
      css`
        border-top-left-radius: var(--radius-blocks--desktop);
        border-top-right-radius: var(--radius-blocks--desktop);
        & > :first-child {
          border-top-left-radius: var(--radius-blocks--desktop);
          border-top-right-radius: var(--radius-blocks--desktop);
        }
      `}
    ${({ $roundedCorners }) =>
      ($roundedCorners === RoundedCorners.Both ||
        $roundedCorners === RoundedCorners.Bottom) &&
      css`
        border-bottom-left-radius: var(--radius-blocks--desktop);
        border-bottom-right-radius: var(--radius-blocks--desktop);
        & > :last-child {
          border-bottom-left-radius: var(--radius-blocks--desktop);
          border-bottom-right-radius: var(--radius-blocks--desktop);
        }
      `}
  }
`
