import tracking from 'src/tracking'

import { ActionButtonVariant } from '../../components/atoms/ActionButton/styled'
import { Icons } from '../../components/atoms/Icon'
import { RelatedRangeProps } from '../../components/molecules/RelatedRange'
import type { Cheese, Format } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'

import { wediaImagesTransform } from './wedia'

export const toRange = (
  t: any,
  data: Cheese,
  popinHandler?: (data: Format) => void,
  withoutButton?: boolean
): RelatedRangeProps => ({
  title: data?.title ?? '',
  imageProps: {
    withHD: true,
    maxWidth: 600,
    width: 600,
    height: 600,
    alt: data?.title ?? ' ',
    images: wediaImagesTransform(data?.thumbnail01 ?? data.pictureHeader),
  },
  actionButtonProps: !withoutButton
    ? {
        label: t('brand_cheese_details'),
        href: Router.getRouteUrl(routes.cheese, { slug: data?.slug }),
        iconPosition: 'right',
        iconProps: {
          icon: Icons.arrowRight,
        },
        onClick: () => tracking.brand.range(),
      }
    : undefined,
  products: data?.formats?.map((format) => ({
    title: format?.title,
    imageProps: {
      withHD: true,
      maxWidth: 170,
      width: 170,
      height: 170,
      alt: format?.title,
      images: wediaImagesTransform(format?.thumbnail ?? format?.packshot),
    },
    actionButtonsProps: [
      {
        label: t('buy'),
        href: undefined,
        onClick: () =>
          tracking.purchase(
            format.title,
            format.mainBrand?.name ?? '',
            'direct'
          ),
        c2bEan: format?.ean,
      },
      {
        label: t('product_more_info'),
        href: format?.slug
          ? Router.getRouteUrl(routes.format, { slug: format.slug })
          : undefined,
        onClick: (e) => {
          e.preventDefault()
          popinHandler?.(format)
        },
        variant: ActionButtonVariant.secondary,
      },
    ],
  })),
})
