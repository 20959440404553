import styled from 'styled-components'

import ActionButton from '../../atoms/ActionButton'
import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Html from '../../atoms/Html'

import { HighlightImageRatio, HighlightLayout } from './types'

export const HighlightBlock = styled.section<{ $layout?: HighlightLayout }>`
  display: flex;
  flex-flow: column nowrap;
  gap: 3rem;
  border-radius: 2rem;
  padding: 2rem 1.5rem 1rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
    display: grid;
    grid-template-columns: ${({ $layout }) =>
      $layout === HighlightLayout.Half ? '1fr 1fr' : '2fr 1fr'};
    gap: 0;
    align-items: flex-start;
    padding: 2rem;
    border-radius: 3rem;
  }
`
export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  & > :not(:first-child) {
    margin-top: 2rem;
  }
  @media ${mq(sizes.tablet)} {
    align-self: center;
    padding: 2rem;
    &:only-child {
      grid-column: 1 / -1;
    }
  }
  @media ${mq(sizes.desktop)} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`
export const Theme = styled.p`
  ${({ theme }) => theme.textStyles.titleH5}
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  text-transform: uppercase;
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3}
  margin: 0;
  color: ${({ theme }) => theme.colors.ruby};
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH2}
  }
`
export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textCaptions}
  color: ${({ theme }) => theme.colors.arapawa};
  & > * {
    margin: 0;
  }
  & > :not(:first-child) {
    margin-top: 1rem;
  }
  @media ${mq(sizes.tablet)} {
  }
`
export const Action = styled(ActionButton)`
  align-self: center;
  @media ${mq(sizes.tablet)} {
    align-self: flex-start;
  }
`
export const StyledPicture = styled(Picture)<{
  $imageRatio?: HighlightImageRatio
  objectFitContain?: boolean
}>`
  border-radius: 1rem;
  overflow: hidden;
  align-self: center;
  aspect-ratio: ${({ $imageRatio }) =>
    $imageRatio === HighlightImageRatio.Flat ? '580 / 260' : '4 / 3'};
  &,
  & img {
    display: block;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: ${({ objectFitContain }) =>
      objectFitContain === true ? 'contain' : 'cover'};
  }
  @media ${mq(sizes.tablet)} {
    align-self: flex-start;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`
