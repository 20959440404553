import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'
import { RoundedCorners } from './types'

export type BaggyProps = {
  className?: string
  children?: React.ReactNode
  roundedCorners?: RoundedCorners
  backgroundColor?: string
  isRoundedOnMobile?: boolean
}

const Baggy: FC<BaggyProps> = (props) => {
  const {
    className,
    children,
    roundedCorners,
    backgroundColor,
    isRoundedOnMobile,
  } = props

  return (
    <SC.Baggy className={cx('Baggy', className)}>
      <SC.Container
        $backgroundColor={backgroundColor}
        $isRoundedOnMobile={isRoundedOnMobile}
        $roundedCorners={roundedCorners}
      >
        {children}
      </SC.Container>
    </SC.Baggy>
  )
}

export default Baggy
