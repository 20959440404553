import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../../atoms/Picture'
import { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'

export type RelatedProductProps = MinimalSeoBlock & {
  className?: string
  title?: string
  imageProps?: PictureProps
  actionButtonsProps?: ActionButtonProps[]
}

const RelatedProduct: FC<RelatedProductProps> = (props) => {
  const {
    className,
    htmlTag,
    title,
    htmlTitleTag,
    imageProps,
    actionButtonsProps,
  } = props

  return (
    <SC.RelatedProduct className={cx('RelatedProduct', className)} as={htmlTag}>
      {imageProps && <SC.StyledPicture {...imageProps} />}
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      {actionButtonsProps && actionButtonsProps.length > 0 && (
        <SC.Actions>
          {actionButtonsProps.map((action, index) => (
            <SC.Action {...action} key={`RelatedProduct-action-${index}`} />
          ))}
        </SC.Actions>
      )}
    </SC.RelatedProduct>
  )
}

export default RelatedProduct
