import tracking, { TrackingCardPosition } from 'src/tracking'

import { ArticleUnion } from '../../graphql/generated/api-graphql'
import Router from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { CardContentType } from '../../components/molecules/ContentCard'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { ArticleCardProps } from '../../components/molecules/ArticleCard'
import { resolveArticleRoute } from '../article/resolveArticleRoute'

export const transformArticleCard = (
  t: any,
  data?: ArticleUnion,
  blockName?: string,
  isHP = false
): ArticleCardProps => {
  const article: ArticleUnion = data as ArticleUnion

  return {
    type: CardContentType.Article,
    title: isHP ? data?.title_homepage ?? data?.title : data?.title ?? '',
    ...((data?.slug && data.__typename === 'CulinaryArticle') ||
    (data?.slug && data.__typename === 'Article') ||
    (data && 'type' in data && data?.type)
      ? {
          linkProps: {
            href: Router.getRouteUrl(resolveArticleRoute(data), {
              slug: data?.slug,
            }),
            onClick: () =>
              tracking.cards.block(
                blockName,
                CardContentType.Article,
                data.title,
                TrackingCardPosition.CONTENT
              ),
          },
        }
      : {}),
    ...(article?.brand && {
      brandLogoProps: article?.brand?.pictureLogo
        ? {
            alt: article?.brand?.pictureLogo?.alt ?? ' ',
            maxWidth: 80,
            width: 80,
            height: 80,
            withHD: true,
            withLazyLoading: true,
            images: wediaImagesTransform(article.brand.pictureLogo),
          }
        : undefined,
    }),
    imageProps: {
      maxWidth: 450,
      width: 384,
      height: 240,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
      placeholder: !data?.thumbnail01 && !data?.mainImage,
      alt: data?.title ?? data?.thumbnail01?.alt ?? data?.mainImage?.alt ?? '',
    },
    mobileImageProps: {
      maxWidth: 140,
      width: 140,
      height: 140,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
      placeholder: !data?.thumbnail01 && !data?.mainImage,
      alt: data?.title ?? data?.thumbnail01?.alt ?? data?.mainImage?.alt ?? '',
    },
    tags: [
      // drop all the tags in order then slice the first 2
      ...(data?.positiveFood
        ? [
            {
              label: t('positive_food'),
              variant: TagVariant.positive,
            },
          ]
        : []),
      { label: t('tag_article'), variant: TagVariant.article },
      ...(article &&
      'tagsConsumptionTime' in article &&
      article?.tagsConsumptionTime?.[0]
        ? [
            {
              label: article?.tagsConsumptionTime?.[0]?.name,
              variant: TagVariant.meal,
            },
          ]
        : []),
      ...(article && 'tagsTheme' in article && article?.tagsTheme?.[0]
        ? [
            {
              label: article?.tagsTheme?.[0]?.name,
              variant: TagVariant.meal,
            },
          ]
        : []),
      ...(article && 'tagTheme' in article && article?.tagTheme
        ? [
            {
              label: article?.tagTheme?.name,
              variant: TagVariant.meal,
            },
          ]
        : []),
      ...(article && 'tagHealthTheme' in article && article?.tagHealthTheme
        ? [
            {
              label: article?.tagHealthTheme?.name,
              variant: TagVariant.diet,
            },
          ]
        : []),
      ...(article?.brand
        ? [{ label: t('tag_brand'), variant: TagVariant.brand }]
        : []),
    ].slice(0, 2),
  }
}
