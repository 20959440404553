import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../../atoms/Picture'
import { RelatedProductProps } from '../RelatedProduct'
import { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'

export type RelatedRangeProps = MinimalSeoBlock & {
  className?: string
  title?: string
  imageProps?: PictureProps
  actionButtonProps?: ActionButtonProps
  products?: RelatedProductProps[]
}

const RelatedRange: FC<RelatedRangeProps> = (props) => {
  const {
    className,
    htmlTag,
    title,
    htmlTitleTag,
    imageProps,
    actionButtonProps,
    products,
  } = props

  return (
    <SC.RelatedRange className={cx('RelatedRange', className)} as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      <SC.Content>
        {imageProps && (
          <SC.IllustrationMobile>
            <SC.StyledPicture {...imageProps} />
            {actionButtonProps && <SC.StyledAction {...actionButtonProps} />}
          </SC.IllustrationMobile>
        )}
        {products && products.length > 0 && (
          <SC.Products $withPicture={imageProps ? true : false}>
            {imageProps && (
              <SC.IllustrationDesktop>
                <SC.StyledPicture {...imageProps} />
                {actionButtonProps && (
                  <SC.StyledAction {...actionButtonProps} />
                )}
              </SC.IllustrationDesktop>
            )}
            {products.map((product, index) => (
              <SC.StyledProduct
                {...product}
                key={`RelatedRange-product-${index}`}
              />
            ))}
          </SC.Products>
        )}
      </SC.Content>
    </SC.RelatedRange>
  )
}

export default RelatedRange
